<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Relatório de Exames</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items"
          item-key="id"
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-expansion-panels class="mb-5" :value="0">
              <v-expansion-panel>
                <v-expansion-panel-header> Filtros </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container fluid>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="filters.type"
                          :items="[
                            { text: 'Público', value: 'public' },
                            { text: 'Privado', value: 'private' },
                          ]"
                          label="Tipo"
                        >
                        </v-select
                      ></v-col>
                    </v-row>
                    <v-row
                      ><v-col>
                        <v-text-field
                          label="Paciente"
                          placeholder="Pesquise pelo nome/cpf/rg do paciente"
                          v-model="filters.patient"
                        >
                        </v-text-field
                      ></v-col>
                      <v-col
                        ><v-select
                          class="mr-2"
                          v-model="filters.ExamReferenceId"
                          :items="examModes"
                          label="Exame"
                        >
                        </v-select
                      ></v-col>
                    </v-row>
                    <v-row v-if="filters.type === 'public'">
                      <v-col
                        ><v-select
                          class="mr-2"
                          v-model="filters.PartnershipId"
                          :items="partnerships"
                          label="Empresa / Órgão conveniado"
                        >
                        </v-select
                      ></v-col>
                    </v-row>

                    <v-row v-if="filters.type === 'private'">
                      <v-col>
                        <v-select
                          :loading="loadingCompanyBranches"
                          label="Filial/Matriz:"
                          v-model="filters.companyBranch"
                          :items="filteredCompanyBranches"
                          item-value="id"
                          item-text="companyName"
                        ></v-select>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-text-field
                          label="Data inicial"
                          v-model="filters.startDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="Data final"
                          v-model="filters.endDate"
                          type="date"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <div class="d-flex justify-end align-center flex-wrap">
                      <v-btn
                        color="blue white--text"
                        class="mr-2"
                        @click="generateReports"
                      >
                        <v-icon left>mdi-content-save</v-icon> Gerar e
                        baixar</v-btn
                      >
                      <v-btn color="success" class="mr-2" @click="loadExams()">
                        <v-icon left>mdi-creation</v-icon> Gerar</v-btn
                      >
                      <v-btn @click="clearFilters"
                        ><v-icon left>mdi-eraser</v-icon> Limpar</v-btn
                      >
                    </div>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Ver exame" @click="openReportScreen(item.id)">
              <v-icon> mdi-eye </v-icon>
            </v-btn>
            <v-btn
              icon
              title="Baixar laudo"
              @click="genMedicalReportPDF(item.id)"
            >
              <v-icon> mdi-file-pdf-box </v-icon>
            </v-btn>
            <v-btn
              icon
              title="Refazer laudo"
              @click="remakeMedicalReport(item.id)"
            >
              <v-icon> mdi-refresh </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <div class="text-center mt-2">
      <v-pagination
        v-model="currentPage"
        :length="numberOfPages"
        @input="loadExams"
      ></v-pagination>
    </div>

    <v-dialog v-model="loadingDialog" persistent max-width="290">
      <v-card>
        <div class="pa-5 d-flex justify-center align-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import moment from "moment";
import { baseUrl } from "@/config/config.json";
import { saveAs } from "file-saver";
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      resultsPerPage: 50,
      totalRows: 0,
      search: null,
      loading: false,
      loadingDialog: false,
      loadingExamModes: false,
      loadingCompanyBranches: false,
      loadingPartnerships: false,
      headers: [
        { text: "ID", value: "id" },
        { text: "Data", value: "dateOfRealization" },
        { text: "Paciente", value: "patientName" },
        { text: "Exame", value: "examName", sortable: false },
        { text: "Modalidade", value: "examModeName" },
        {
          text: "Subspecialidade",
          value: "examSubspecialtyName",
          sortable: false,
        },
        { text: "Ações", value: "actions", sortable: false },
      ],
      items: [],
      examModes: [],
      partnerships: [],
      companyBranches: [],
      currentPage: 1,
      filters: {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
        type: "private",
      },
    };
  },
  methods: {
    async loadExams(page) {
      try {
        this.loading = true;

        let currentPage = page ? page : 1;

        const params = {
          pagination: true,
          currentPage: currentPage,
          ...this.filters,
        };

        let url = `/reports/exams`;

        const response = await this.$axios.get(url, {
          params,
        });

        this.totalRows = response.data.count ? response.data.count : 0;

        this.setResponseData(response.data.rows);

        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },

    async fetchReport() {
      const params = {
        ...this.filters,
      };

      let url = `/reports/exams/download`;

      const response = await this.$axios.get(url, {
        params,
        responseType: "arraybuffer",
      });

      return response.data;
    },
    openInsertScreen() {
      this.$router.push("/exames/cadastrar");
    },
    openReportScreen(examId) {
      this.$router.push(`/exames/${examId}/laudo/editar`);
    },
    clearFilters() {
      this.filters = {
        ExamReferenceId: null,
        patient: null,
        PartnershipId: null,
        startDate: null,
        endDate: null,
        type: "public",
      };
    },
    setResponseData(data) {
      const copyOfData = [...data];

      // console.log(copyOfData);

      this.items = copyOfData.map((item) => {
        item.patientName =item.Patient.Person.fullName;

        item.examName =
          item.type === "public"
            ? item.ExamReference.Procedure.name
            : item.SchedulingProcedure.Procedure.name;

        item.examModeName =
          item.type === "public"
            ? item.ExamReference.ExamMode.name
            : item.SchedulingProcedure.Procedure.ExamReference.ExamMode.name;

        item.examSubspecialtyName =
          item.type === "public"
            ? item.ExamReference.ExamSubspecialty.name
            : item.SchedulingProcedure.Procedure.ExamReference.ExamSubspecialty
                .name;

        item.dateOfRealization = moment(item.dateOfRealization).format(
          "DD/MM/YYYY [ás] HH:mm"
        );

        return item;
      });
    },
    async remakeMedicalReport(examId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção",
            "Deseja realmente refazer o laudo do exame?",
            {
              color: "primary",
            }
          ))
        )
          return;

        this.loading = true;

        const url = `/exams/${examId}/status`;

        await this.$axios.put(url, { status: "pending" });

        this.deleteExamFromDataTable(examId);

        this.loading = false;
      } catch (error) {
        this.loading = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    deleteExamFromDataTable(examId) {
      const index = this.items.findIndex((item) => item.id == examId);

      if (index != -1) {
        let copyOfItems = [...this.items];

        copyOfItems.splice(index, 1);

        this.items = copyOfItems;
      }
    },
    async loadExam(examId) {
      const url = `/exams/${examId}`;

      const res = await this.$axios.get(url);

      return { ...res.data };
    },
    async loadExamModes() {
      try {
        this.loadingExamModes = true;

        this.examModes = [];

        const url = `/exam-modes`;

        const res = await this.$axios.get(url, {
          params: {
            withExamReferences: true,
          },
        });

        this.setExamModesData(res.data);
        this.loadingExamModes = false;
      } catch (error) {
        console.log(error);
        this.loadingExamModes = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setExamModesData(data) {
      const copyOfData = [...data];

      for (const mode of copyOfData) {
        this.examModes.push({
          header: mode.name,
        });

        // console.log(mode);
        for (const examReference of mode.ExamReferences) {
          // console.log(examReference);
          this.examModes.push({
            value: examReference.id,
            text: examReference.Procedure.name,
          });
        }

        this.examModes.push({
          divider: true,
        });
      }
    },
    urgentExamStatusColor(status) {
      if (status === 1) return "success";
      else if (status === 2) return "warning";
      else if (status === 3) return "error";
    },
    async loadPartnerships() {
      try {
        this.loadingExamPartnerships = true;

        const url = `/partnerships`;

        const res = await this.$axios.get(url);

        this.setPartnerships(res.data);

        this.loadingExamPartnerships = false;
      } catch (error) {
        this.loadingExamPartnerships = false;

        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    setPartnerships(data) {
      const copyOfData = [...data];

      this.partnerships = copyOfData.map((p) => ({
        text: p.Person.fantasyName,
        value: p.id,
      }));
    },
    async loadCompanyBranches() {
      try {
        this.loadingCompanyBranches = true;

        const url = `/company-branches`;

        const res = await this.$axios.get(url);

        this.setCompanyBranches(res.data);
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loadingCompanyBranches = false;
      }
    },
    setCompanyBranches(data) {
      const copyOfData = [...data];

      this.companyBranches = copyOfData;
    },
    async genMedicalReportPDF(examId) {
      try {
        window.open(`${baseUrl}/exams/${examId}/medical-report/pdf`, "_blank");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      }
    },
    async generateReports() {
      try {
        this.loadingDialog = true;

        const data = await this.fetchReport();

        const file = new Blob([data], { type: "application/zip" });

        saveAs(file, "report.zip");
      } catch (error) {
        const errorHandled = errorHandler.treatError(error);

        await this.$root.$errorDialog(errorHandled, {
          width: "800",
          color: "primary",
        });
      } finally {
        this.loadingDialog = false;
      }
    },
  },
  computed: {
    ...mapState("user", ["userData"]),
    numberOfPages() {
      return Math.ceil(this.totalRows / this.resultsPerPage);
    },
    filteredCompanyBranches() {
      const { companyBranches, userHasBranchRestriction } = this.userData;

      return userHasBranchRestriction
        ? this.companyBranches.filter((b) => companyBranches.includes(b.id))
        : this.companyBranches;
    },
  },
  created() {
    this.loadExamModes();
    this.loadPartnerships();
    this.loadCompanyBranches();
  },
};
</script>

<style></style>
